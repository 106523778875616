import React from "react"


export default function HeaderImageDescription(props) {
    return (
      <div className="bg-blue-50">
        <div className="max-w-2xl mx-auto px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
          <section aria-labelledby="details-heading">
            <div className="flex flex-col items-center text-center">
              <h2 id="details-heading" className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {props.heading}
              </h2>
              <p className="mt-3 max-w-3xl text-lg text-gray-600">
                {props.description}
              </p>
            </div>
  
            <div className="mt-16 grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:gap-x-8">
              <div>
                <div className="w-full rounded-lg overflow-hidden">
                  { props.children[0] }
                </div>
                <p className="mt-8 text-base text-gray-500">
                  { props.firstImageDescription }
                </p>
              </div>
              <div>
                <div className="w-full rounded-lg overflow-hidden">
                    { props.children[1] }
                </div>
                <p className="mt-8 text-base text-gray-500">
                  { props.secondImageDescription }
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }

HeaderImageDescription.defaultProps = {

    heading: "The Fine Details",
    description: "Our patented padded snack sleeve construction protects your favorite treats from getting smooshed during all-day adventures, long shifts at work, and tough travel schedules.",

    firstImageSrc: "https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg",
    firstImageAlt: "Drawstring top with elastic loop closure and textured interior padding.",
    firstImageDescription: "The 20L model has enough space for 370 candy bars, 6 cylinders of chips, 1220 standard gumballs, or any combination of on-the-go treats that your heart desires. Yes, we did the math.",
    
    secondImageSrc: "https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-02.jpg",
    secondImageAlt: "Front zipper pouch with included key ring.",
    secondImageDescription: "Up your snack organization game with multiple compartment options. The quick-access stash pouch is ready for even the most unexpected snack attacks and sharing needs."
}