import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "/src/components/layout"

import AlternatingImages from "/src/components/products/alternatingimages"
import HeaderImageDescription from "/src/components/products/headerimagedescription"
import TechnicalSpecs from "/src/components/products/technicalspecsimagegrid"
import WideImages from "../../components/products/wideimages"
import CallToAction from "../../components/calltoaction"

export default function Example() {
    return (
        <Layout>
            <div className="bg-white">
                <div className="max-w-7xl mx-auto pt-16 px-4 sm:pt-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <StaticImage
                            src="../../images/neue_CI_HL_Logo_Normal.png"
                            alt="Holm & Laue Logo"
                            className="object-center object-cover mb-8"
                            width={256}
                        />
                        <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">The modern calf feeder</h2>
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            CalfExpert
                        </p>
                        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                            Individual calf feeding, whole milk system, intuitive control for the graphic display and many other features.
                        </p>
                    </div>
                </div>
            </div>
            <WideImages
                title="Individual animal feeding"
                heading="A modern calf feeder"
                description="CalfExpert is a calf feeder that has been developed to meet the latest standards in calf feeding. State-of-the-art feeding technology ensures that the milk for the calves is always freshly mixed. It does not matter whether milk replacer or pasteurised whole milk is used. CalfExpert prepares the milk automatically and individually for each animal."
                features={
                    [
                        {
                            name: '24/7 Feeding',
                            description: 'You, as farmer, remain completely flexible in your work schedule while calves are fed 24/7. The calf feeder takes your calf rearing management to a whole new level.',
                        },
                        {
                            name: 'Intuitive Operation',
                            description: 'Intuitive operation via the graphic display and the link to the CalfGuide mobile app make it easier for you and your team to monitor your animals. All the information about your calves, status reports and alerts are at your fingertips in the app and directly on the calf feeder at all times.',
                        },
                        {
                            name: 'Ready to work whatever the weather',
                            description: 'Calves love the outdoor climate, your CalfExpert does too. It comes with a frost protection programme that is activated automatically as soon as there is a risk of frost. Sensors in every HygieneStation and in the CalfExpert mixing chamber measure the ambient temperature and then adapt the mixing temperature and intensity of the frost protection programme accordingly. You can clean the outer surfaces of the automatic feeder with water, since all the sensitive components are water spray-proof.',
                        },
                    ]
                }
            >
                <StaticImage
                    src="../../images/products/calfexpert/Seite_12_oben_gross.jpg"
                    alt="The CalfExpert machine set up in a barn next to a pen full of cows."
                    className="object-center object-cover"
                    aspectRatio={5/2}
                />
                <StaticImage
                    src="../../images/products/calfexpert/CEX_6_display_hand.jpg"
                    alt="A worker is pressing buttons on the CalfExpert built in display."
                    className="object-center object-cover"
                    aspectRatio={5/2}
                />
                <StaticImage
                    src="../../images/products/calfexpert/CEX_2019.jpg"
                    alt="A close up of the CalfExpert weatherproof housing."
                    className="object-center object-cover"
                    aspectRatio={5/2}
                />
            </WideImages>
            <HeaderImageDescription
                heading="Flexible feeding programmes"
                description="Modern feeding programmes should always adapt to the age and breed, personally set rearing goals and state of health of the calves. CalfExpert allows you to do all this in the optimum way."
                firstImageDescription="Flexible feeding also means that a wide range of CMRs and additives have to be prepared in an optimum and lump-free way. The continuously variable PowerMixer ensures a soft start to avoid splashing and then stirs the milk replacer powder thoroughly. This means the calf receives its freshly mixed milk directly at the teat in just 3 seconds."
                secondImageDescription="Minimal doses of feed additives can be added to the milk diet in order to stabilise the gastrointestinal system. Sick calves can be treated with medicines in the milk. You can use two powder dosing units and two liquid dosing units. As with the milk programme, you can set different feeding curves for these additives as well. This means maximum flexibility for top calf health." 
            >
                <StaticImage
                    src="../../images/products/calfexpert/DSC_1220.jpg"
                    alt="Powdered milk and water being dispensed and mixed by the CalfExpert"
                    className="w-full h-full object-center object-cover"
                    aspectRatio={3/2}
                />
                <StaticImage
                    src="../../images/products/calfexpert/Pulver-und-Fluessigdosierer.jpg"
                    alt="A close up image of the medicine dispensing jugs inside the CalfExpert"
                    className="w-full h-full object-center object-cover"
                    aspectRatio={3/2}
                />
            </HeaderImageDescription>
            <AlternatingImages
                heading="Clean and reliable"
                description="A calf feeder is designed to make your work easier and perform continually for 24 hours. To achieve this it has to work reliably under the rough conditions of a calf stall, like the CalfExpert does."
                features={
                    [
                        {
                            name: 'Hose cleaning including the teat',
                            description: 'All hose systems used to have deposits of contaminated milk because they had not been cleaned often enough or thoroughly enough. The HygieneStation rinses the entire milk hose through to the teat during longer drinking breaks. In addition, the complete milk system is cleaned several times every day. If the DoubleJug milk tank is used, the supply line to the CalfExpert is also cleaned automatically. This will reduce a calf’s exposure to bacteria, improve the health of the calves and save on manual cleaning.',
                        },
                        {
                            name: 'Two cleaning agents',
                            description: 'To optimise cleaning even further, the CalfExpert can use two different cleaning agents: either alternate with each cleaning cycle or combine in one cleaning cycle (alkaline, rinse, acid, rinse). The cleaning agent pumps are fitted on the outside. They can thus draw directly from the tanks. This is safe and convenient. In addition, the CalfExpert generates automatic messages as soon as a canister is empty.',
                        },
                    ]
                }
            >
                <StaticImage
                    src="../../images/products/calfexpert/7-Cleaning-Hose.jpg"
                    alt="A view of the various hoses inside the CalfExpert."
                    className="object-center object-cover"
                    aspectRatio={5/2}
                />
                <StaticImage
                    src="../../images/products/calfexpert/DSC_1171.jpg"
                    alt="The two separate cleaning solutions and external peristaltic pumps attached to the exterior of the CalfExpert."
                    className="object-center object-cover"
                    aspectRatio={5/2}
                />
            </AlternatingImages>
            <TechnicalSpecs
                heading="CalfExpert Technical Specifications"
                description=""
                features={[
                    { name: 'Power supply and heating performance', description: '400 V 16 A (6 kW) or 230 V 16 A (3 kW)' },
                    { name: 'Capacity with 4 feeder stations', description: 'Up to 100 calves (up to 150 in certain cases)' },
                    { name: 'Identification system', description: 'Multireader HDX and FDX' },
                    { name: 'Storage tank for milk replacer powder', description: '90 l / 50 kg'},
                    { name: 'Powder filling height', description: '112 cm' },
                    { name: 'Boiler capacity', description: '12 l' },
                    { name: 'Dimensions', description: '60cm x 70 cm x 121 cm' },
                    { name: 'Space requirement', description: '100 cm x 100 cm' },

                ]}
            >
                <StaticImage
                    src="../../images/products/calfexpert/CEX_5_calves.jpg"
                    alt="MilkTaxi being pushed"
                    className="w-full h-96 object-center object-cover"
                />
            </TechnicalSpecs>
            <CallToAction
                title="Want to learn more?"
                description="Visit Holm & Laue or contact us directly."
                url="https://www.holm-laue.com/calfexpert/"
            >
            </CallToAction>
        </Layout>
    )
}
